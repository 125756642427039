/**
 * @category Redux
 * @subcategory bookSearch
 * @namespace slice:searchUtility
 * @description searchUtility is utility file has search operations snippets.
 */

/**
 * @memberof slice:searchUtility
 * @method searchBody
 * @description Toggle show more accordion options.
 * @param {string} search User searched text.
 * @param {string=} searchType search type is not mandatory.
 * @param {string} pageIndex Current search page index.
 * @param {Array<Object>} filters Cached search filters.
 * @param {Object} userFilter user search filter.
 *
 * @returns {Object} {
 * search:string,
 * pageIndex:string,
 * searchType:string,
 * author:Array<Object>,
 * publicationHouse:Array<Object>,
 * publishDate:Array<Object>
 * }
 */
export const searchBody = (
  search,
  pageIndex,
  searchType,
  filters,
  userFilter
) => {
  const author = validateUserFilter(
    getValidObjects(filters, "Author"),
    userFilter,
    "Author"
  );
  const publicationHouse = validateUserFilter(
    getValidObjects(filters, "PublicationHouse"),
    userFilter,
    "PublicationHouse"
  );
  const publishDate = validateUserFilter(
    getValidObjects(filters, "PublishDate"),
    userFilter,
    "PublishDate"
  );

  return {
    search,
    pageIndex,
    searchType,
    author,
    publicationHouse,
    publishDate,
  };
};

/**
 * @memberof slice:searchUtility
 * @method getValidObjects
 * @description Filter out search filter accordion summary options
 * @param {Array<Object>} data Search filter options.
 * @param {string} key Accordion key.
 *
 * @returns {Array<Object>} Filtered out selected search filter by key
 */
const getValidObjects = (data, key) => {
  const newData = data
    ?.find((item) => item.name === key)
    ?.options.filter((option) => option.isSelected)
    ?.map((option) => option.value);
  return newData ? newData : null;
};

/**
 * @memberof slice:searchUtility
 * @method validateUserFilter
 * @description Validate user selected search filter.
 * @param {Array<Object>} data Search filter options.
 * @param {Object} userFilter Search filter options.
 * @param {string} key Accordion key.
 *
 * @returns {Array<Object>} Validate selected search filter by key
 */
const validateUserFilter = (data, userFilter, key) => {
  if (key === userFilter?.name) {
    if (userFilter?.isSelected) {
      return data
        ? data?.filter((v) => v !== userFilter?.value)
        : [userFilter.value];
    } else {
      return data ? [...data, userFilter?.value] : [userFilter?.value];
    }
  }
  return data ? data : null;
};
