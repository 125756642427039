import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { getBookmarkById } from "./getBookmarkById";

/**
 * @category Redux
 * @subcategory bookmark
 * @namespace slice:bookResumeSlice
 * @description bookResumeSlice is slice for posting book resume bookmark.
 */

/**
 * @memberof slice:bookResumeSlice
 * @method bookResumeAdd
 * @async
 * @description Rest api to post book resume bookmark.
 * @param {string=} bookId Book id for the bookmark.
 * @param {string} cfi Book cfi id for the bookmark.
 * @param {string} chapterName Book chapterName for the bookmark.
 * @param {string} color color for the bookmark.
 * @param {string} text Book text for the bookmark.
 *
 */

export const bookResumeAdd = createAsyncThunk(
  "book/bookResumeAdd",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(`api/bookmark/book-resume`, data);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getBookmarkById({ bookId: data?.bookId }));
          return response.data.status.statusMessage;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/**
 * @memberof slice:bookResumeSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const bookResumeSlice = createSlice({
  name: "bookResume",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookResumeAdd.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bookResumeAdd.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });
    builder.addCase(bookResumeAdd.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:bookResumeSlice
     * @method clearBookResumeAdd
     * @description Clear the bookResumeSlice.
     */
    clearBookResumeAdd: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearBookResumeAdd } = bookResumeSlice.actions;

export default bookResumeSlice.reducer;
