import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:bookDataSlice
 * @description book data redux.
 */

/**
 * @memberof slice:bookDataSlice
 * @name hiddenSearch
 * @description Hidden search variable.
 * @typedef {Object} hiddenSearchParam
 * @property {string} h1
 * @property {string} h2
 * @property {string} h3
 * @property {string} h4
 * @property {string} p
 * @property {string} href
 * @property {string} text
 */

const hiddenSearch = {
  h1: "",
  h2: "",
  h3: "",
  h4: "",
  p: "",
  href: "",
  text: "",
};

/**
 * @memberof slice:bookDataSlice
 * @name hiddenSearch
 * @description Initial slice state
 * @typedef {Object} hiddenSearchParam
 * @property {string} bookId Book id.
 * @property {string} articleId Article id for book.
 * @property {string} title Title of the book.
 * @property {object} rendition Rendition of the book.
 * @property {string} currentLocation Current location in the book.
 * @property {Array<Object>} tocData Toc of the book.
 * @property {Array<Object>} chapterArray Book chapters.
 * @property {string} location  Location inside the book.
 * @property {boolean} isBookReady Is the book reader for use.
 * @property {boolean} isBookPaginated Is the book paginated.
 * @property {string} searchText Search text inside book.
 * @property {boolean} autoIndicate Auto jump to required search result.
 * @property {string} previousSearchCfi Previous search cfi.
 * @property {Object} bookmarked Bookmark object.
 * @property {boolean} fontSizeMenu Toggle fontsize menu popup.
 * @property {boolean} fontFlagCheck Font flag.
 * @property {boolean} hiddenSearch Is Hidden search.
 * @property {hiddenSearchParam} searchLoadingFlag Hidden search.
 * @property {boolean} pageNumberEdit Page number edit.
 */

const initialState = {
  bookId: "",
  articleId: "",
  title: "",
  rendition: null,
  currentLocation: "",
  tocData: [],
  chapterArray: [],
  location: "",
  type: "",
  isBookReady: false,
  isBookPaginated: false,
  searchText: "",
  autoIndicate: false,
  previousSearchCfi: "",
  bookmarked: {
    isBookmarked: false,
    bookmarkId: 0,
  },
  fontSizeMenu: false,
  fontFlagCheck: false,
  hiddenSearch: hiddenSearch,
  searchLoadingFlag: false,
  pageNumberEdit: false,
};

/**
 * @memberof slice:bookDataSlice
 * @method getChapterArray
 * @description Get the chapter array from the toc data.
 * @param {string} title Default value if chapter title not found.
 * @param {Object} tocData Toc data from the book.
 *
 * @returns {Object} Chapter array.
 */

const getChapterArray = (title, tocData) => {
  const length = tocData?.length ? tocData?.length : 0;
  let index = 0;
  const chapArray = [];
  while (index < length) {
    const obj = tocData[index];
    chapArray.push({
      id: obj?.id?.split(".")?.[0],
      label: obj?.label,
    });
    index++;
  }
  if (chapArray.length > 0 && title) {
    chapArray.push({
      id: title,
      label: title,
    });
  }
  return chapArray;
};

export const bookDataSlice = createSlice({
  name: "bookData",
  initialState,
  reducers: {
    /**
     * @memberof slice:bookDataSlice
     * @method setBookIdLocation
     * @description Set the book id and current location.
     * @param {Object} payload {bookId:string,currentLocation:string}
     *
     */
    setBookIdLocation: (state, action) => {
      state.bookId = action.payload.bookId;
      state.currentLocation = action.payload.currentLocation;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setBookIdTitle
     * @description Set the book id, article id and title of the book.
     * @param {Object} payload {title:string,articleId:string, bookId:string}
     *
     */
    setBookIdTitle: (state, action) => {
      state.title = action.payload.title;
      state.articleId = action.payload.articleId;
      state.bookId = action.payload.bookId;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setBookCurrentLocation
     * @description Set the book id and current location.
     * @param {string} payload currentLocation
     *
     */
    setBookCurrentLocation: (state, action) => {
      state.isBookReady = true;
      state.currentLocation = action.payload;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setRendition
     * @description Set the book rendition.
     * @param {Object} payload rendition
     *
     */
    setRendition: (state, action) => {
      const rendition = action.payload;
      state.rendition = rendition;
      state.chapterArray = getChapterArray(
        state.title,
        rendition?.book?.navigation?.toc
      );
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setBookPaginated
     * @description Set book paginated and toc data.
     * @param {Object} payload {isBookPaginated:boolean, tocData:Object}
     *
     */
    setBookPaginated: (state, action) => {
      state.isBookPaginated = action.payload.isBookPaginated;
      state.tocData = action.payload.toc;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setToc
     * @description Set book toc data.
     * @param {Object} payload toc data.
     *
     */
    setToc: (state, action) => {
      state.tocData = action.payload;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setGotoPage
     * @description Set goto book location.
     * @param {Object} payload {location:string, type:string}
     *
     */
    setGotoPage: (state, action) => {
      state.location = action.payload.location;
      state.type = action.payload.type;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setSearchText
     * @description Set book search text.
     * @param {Object} payload {searchText:string, autoIndicate:boolean}
     *
     */
    setSearchText: (state, action) => {
      state.searchText = action.payload.searchText;
      state.autoIndicate = action.payload.autoIndicate;
    },
     /**
     * @memberof slice:bookDataSlice
     * @method setPerviousSearchCfi
     * @description Set book search text.
     * @param {string} payload Previous search cfi
     *
     */
    setPerviousSearchCfi: (state, action) => {
      state.previousSearchCfi = action.payload;
    },
     /**
     * @memberof slice:bookDataSlice
     * @method setHiddenSearch
     * @description Set book hidden search.
     * @param {Object} payload {hiddenSearch:Object, searchLoadingFlag:boolean}
     *
     */
    setHiddenSearch: (state, action) => {
      if (action.payload.hiddenSearch) {
        state.hiddenSearch = action.payload.hiddenSearch;
        state.hiddenSearch.text = action.payload.hiddenSearch?.text;
      } else {
        state.hiddenSearch = hiddenSearch;
      }
      state.searchLoadingFlag = action.payload.searchLoadingFlag;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setBookmark
     * @description Set current bookmark.
     * @param {Object} payload {bookmarkId:int, isBookmarked:boolean}
     *
     */
    setBookmark: (state, action) => {
      state.bookmarked.bookmarkId = action.payload.bookmarkId;
      state.bookmarked.isBookmarked = action.payload.isBookmarked;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setFontSizeMenu
     * @description Set current bookmark.
     * @param {string} payload fontsize
     *
     */
    setFontSizeMenu: (state, action) => {
      state.fontFlagCheck = true;
      state.fontSizeMenu = action.payload;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method setPageNumberEdit
     * @description Toggle page number edit.
     * @param {Object} payload Page number edit
     *
     */
    setPageNumberEdit: (state, action) => {
      const isBoolean = typeof action.payload === "boolean";
      state.pageNumberEdit = isBoolean ? action.payload : !state.pageNumberEdit;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method clearPerviousSearchCfi
     * @description Clear pervious search.
     *
     */
    clearPerviousSearchCfi: (state, action) => {
      state.previousSearchCfi = "";
    },
    /**
     * @memberof slice:bookDataSlice
     * @method clearSearchText
     * @description Clear search text.
     *
     */
    clearSearchText: (state, action) => {
      state.searchText = "";
    },
    /**
     * @memberof slice:bookDataSlice
     * @method clearAutoIndicate
     * @description Clear autoIndicate
     *
     */
    clearAutoIndicate: (state, action) => {
      state.autoIndicate = false;
    },
    /**
     * @memberof slice:bookDataSlice
     * @method clearGotoPage
     * @description Clear goto page
     *
     */
    clearGotoPage: (state, action) => {
      state.location = "";
      state.type = "";
    },
    /**
     * @memberof slice:bookDataSlice
     * @method clearBookData
     * @description Clear the bookDataSlice.
     *
     */
    clearBookData: (state, action) => {
      state.bookId = "";
      state.articleId = "";
      state.title = "";
      state.rendition = null;
      state.currentLocation = "";
      state.tocData = [];
      state.isBookPaginated = false;
      state.isBookReady = false;
      state.location = "";
      state.type = "";
      state.chapterArray = [];
      state.searchText = "";
      state.autoIndicate = false;
      state.previousSearchCfi = "";
      state.fontSizeMenu = false;
      state.fontFlagCheck = false;
      state.hiddenSearch = hiddenSearch;
      state.pageNumberEdit = false;
    },
  },
});

export const {
  setBookIdTitle,
  setBookCurrentLocation,
  setBookIdLocation,
  clearBookData,
  setToc,
  setRendition,
  setGotoPage,
  clearGotoPage,
  setBookPaginated,
  setSearchText,
  setBookFrame,
  setPerviousSearchCfi,
  clearPerviousSearchCfi,
  clearAutoIndicate,
  setBookmark,
  setFontSizeMenu,
  setHiddenSearch,
  setPageNumberEdit,
} = bookDataSlice.actions;

export default bookDataSlice.reducer;
