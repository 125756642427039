import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:bookLocationsSlice
 * @description book data redux.
 */

/**
 * @memberof slice:bookLocationsSlice
 * @name initialState
 * @description Initial slice state
 * @property {Array<Object>} locationCache
 * @property {boolean} hasBookLocationCache
 * @property {Array<Object>} currentBookLocation
 */

const initialState = {
  locationCache: [],
  hasBookLocationCache: false,
  currentBookLocation: [],
};

export const getBookLocations = createAsyncThunk(
  "book/getBookLocations",
  async ({ bookId }, { rejectWithValue, getState, dispatch, signal }) => {
    try {
      const locationCache = getState().bookLocations.locationCache;
      const index = locationCache?.findIndex((val) => val.bookId === bookId);
      if (index > -1) {
        return {
          bookId,
          location: locationCache[index].location,
        };
      } else {
        return rejectWithValue(
          `failed to capture location for bookId ${bookId}`
        );
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bookLocationsSlice = createSlice({
  name: "bookLocations",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBookLocations.pending, (state, action) => {
      state.hasBookLocationCache = false;
    });
    builder.addCase(getBookLocations.fulfilled, (state, action) => {
      state.hasBookLocationCache = true;
      state.currentBookLocation = action.payload.location;
    });
    builder.addCase(getBookLocations.rejected, (state, action) => {
      state.hasBookLocationCache = true;
    });
  },
  reducers: {
    /**
     * @memberof slice:bookLocationsSlice
     * @method setBookLocationsWithCache
     * @description Set the book location and book id.
     * @param {Object} payload {bookId:string,location:string}
     */
    setBookLocationsWithCache: (state, action) => {
      const { bookId, location } = action.payload;
      const index = state.locationCache?.findIndex(
        (val) => val.bookId === bookId
      );
      if (index === -1) {
        state.locationCache.push({
          bookId,
          location,
        });
      } else {
        state.locationCache[index] = { bookId, location };
      }
      state.hasBookLocationCache = true;
    },
    /**
     * @memberof slice:bookLocationsSlice
     * @method removeBookLocations
     * @description Remove the book location.
     * @param {Object} payload {bookId:string}
     */
    removeBookLocations: (state, action) => {
      state.currentBookLocation = [];
      const { bookId } = action.payload;
      const index = state.locationCache?.findIndex(
        (val) => val.bookId === bookId
      );
      if (index > -1) {
        state.locationCache?.splice(index, 1);
      }
    },
    /**
     * @memberof slice:bookLocationsSlice
     * @method clearBookLocations
     * @description Clear book location.
     */
    clearBookLocations: (state, action) => {
      state.hasBookLocationCache = false;
      state.currentBookLocation = [];
    },
    /**
     * @memberof slice:bookLocationsSlice
     * @method clearBookLocationsWithCache
     * @description Clear the book location with cache.
     *
     */
    clearBookLocationsWithCache: (state, action) => {
      state.locationCache = [];
      state.hasBookLocationCache = false;
      state.currentBookLocation = [];
    },
  },
});

export const {
  setBookLocationsWithCache,
  clearBookLocations,
  clearBookLocationsWithCache,
  removeBookLocations,
} = bookLocationsSlice.actions;

export default bookLocationsSlice.reducer;
