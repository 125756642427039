import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory auth
 * @namespace slice:logoutSlice
 * @description LogoutSlice is to perform logout operations.
 */

/**
 * @memberof slice:logoutSlice
 * @name initialState
 * @description LogoutSlice initial state object.
 * @typedef {Object} initialState
 * @property {boolean} isLogout Check if able to logout.
 * @property {boolean} autoSignIn Check if auto sign in activated.
 * @property {boolean} sessionLogout Check if session is logged out.
 * @property {boolean} userLogout Check if user has logged out.
 */
const initialState = {
  isLogout: false,
  autoSignIn: true,
  sessionLogout: false,
  userLogout: false,
};

export const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    /**
     * @memberof slice:logoutSlice
     * @method logout
     * @description logout the user.
     * @property {boolean} isLogout
     * @property {boolean} autoSignIn
     */
    logout: (state, action) => {
      state.isLogout = true;
      state.autoSignIn = false;
    },
    /**
     * @memberof slice:logoutSlice
     * @method setUserLogout
     * @description User force logout.
     * @property {boolean} userLogout
     */
    setUserLogout: (state, action) => {
      state.userLogout = action.payload;
    },
    /**
     * @memberof slice:logoutSlice
     * @method setSessionLogout
     * @description Session is logged out
     * @property {boolean} sessionLogout
     */
    setSessionLogout: (state, action) => {
      state.sessionLogout = action.payload;
    },
    /**
     * @memberof slice:logoutSlice
     * @method clearSessionLogout
     * @description Clear session and user logout.
     * @property {boolean} sessionLogout
     * @property {boolean} userLogout
     */
    clearSessionLogout: (state, action) => {
      state.sessionLogout = false;
      state.userLogout = false;
    },
    /**
     * @memberof slice:logoutSlice
     * @method clearLogout
     * @description Clear logoutSlice state.
     * @property {boolean} isLogout
     * @property {boolean} autoSignIn
     * @property {boolean} sessionLogout
     * @property {boolean} userLogout
     */
    clearLogout: (state, action) => {
      state.isLogout = false;
      state.autoSignIn = true;
      state.sessionLogout = false;
      state.userLogout = false;
    },
  },
});

export const {
  logout,
  clearLogout,
  setSessionLogout,
  clearSessionLogout,
  setUserLogout,
} = logoutSlice.actions;

export default logoutSlice.reducer;
