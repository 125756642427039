import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { clearAllGetHighlightById } from "../highlight/getHighlightById";
import { clearAllGetBookmarkById } from "../bookmark/getBookmarkById";
import { clearAllGetNotesById } from "../notes/getNotesById";
import { clearAllGetBookById } from "../bookReaderSlice/getBookByIdSlice";
import { clearBookLocationsWithCache } from "../bookReaderSlice/bookLocationsSlice";
import { UserSession } from "../../../config/userManager";

/**
 * @category Redux
 * @subcategory auth
 * @namespace slice:companyInfoSlice
 * @description companyInfoSlice is slice for company info.
 */

/**
 * @memberof slice:companyInfoSlice
 * @method companyInfo
 * @async
 * @description Rest api to get company info.
 */

export const companyInfo = createAsyncThunk(
  "user/companyInfo",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const response = await myAxios(data, UserSession.sessionOverride).get(
      "api/Account/get-company-info"
    );
    if (response.status === 200) {
      dispatch(clearCount("companyInfo"));
      if (response.data.status.statusCode === 200) {
        if (
          response.data.data?.userId !==
          getState().companyInfo.data?.userId
        ) {
          resetUserBookData(dispatch);
        }
        return response.data.data;
      } else if (response.data.status.statusCode === 204) {
        return {
          customerId: "",
          companyName: "",
        };
      } else {
        return rejectWithValue(response.data.status.errorMessage);
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (getState().reloader["companyInfo"]) {
      dispatch(autoDecrementCount("companyInfo"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else {
      // dispatch(logout());
      return rejectWithValue(err);
    }
  }
};

const resetUserBookData = (dispatch) => {
  dispatch(clearAllGetHighlightById());
  dispatch(clearAllGetBookmarkById());
  dispatch(clearAllGetNotesById());
  dispatch(clearAllGetBookById());
  dispatch(clearBookLocationsWithCache());
};

/**
 * @memberof slice:companyInfoSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const companyInfoSlice = createSlice({
  name: "companyInfo",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(companyInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(companyInfo.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(companyInfo.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
      state.data = null;
    });
  },
  reducers: {
    /**
     * @memberof slice:companyInfoSlice
     * @method clearCompanyInfo
     * @description Clear the company info data.
     */
    clearCompanyInfo: (state, action) => {
      state.hasData = false;
      state.error = null;
      state.hasError = false;
      state.isLoading = false;
      state.data = null;
    },
  },
});

export const { clearCompanyInfo } = companyInfoSlice.actions;

export default companyInfoSlice.reducer;
