import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  clearGroupedSearchText,
  groupedSearchPageIndex,
} from "./groupedSearch";
import { clearListedSearchText, listedSearchPageIndex } from "./listedSearch";
import { SEARCH_TYPE, URLS } from "../../../config";

/**
 * @category Redux
 * @subcategory bookSearch
 * @namespace slice:searchFilterSlice
 * @description searchFilterSlice is slice for performing operation on search filter view.
 */

/**
 * @memberof slice:searchFilterSlice
 * @method clearSearchedText
 * @async
 * @description This method is to reset the search flag both listed and grouped search.
 * @param {string} text Searched text.
 *
 */

export const clearSearchedText = createAsyncThunk(
  "books/searchFilter",
  async (text, { rejectWithValue, getState, dispatch }) => {
    dispatch(resetSearchFilter());
    dispatch(clearGroupedSearchText());
    dispatch(clearListedSearchText());

    if (window.location.pathname === URLS.SEARCH.PATH) {
      const _searchVariant = URLS.SEARCH_PARAMETER(
        URLS.CONSTANTS.SEARCH_VARIANT
      );
      const searchVariant =
        _searchVariant === SEARCH_TYPE.LIST
          ? listedSearchPageIndex
          : groupedSearchPageIndex;
      dispatch(
        searchVariant({
          userSearchText: text,
          searchText: text,
          pageIndex: 0,
          isInitial: true,
        })
      );
    }
  }
);

const searchLimiter = ["Author"];

/**
 * @memberof slice:searchFilterSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {Array<Object>} filters Current filter.
 * @property {boolean} hasFilters Check if filters has data.
 * @property {string} query Search text used.
 * @property {boolean} isOpen Search filter accordion toggler.
 * @property {Array<string>} searchLimiter Accordion summary options limit Author|PublicationHouse|PublishDate in swedish.
 * @property {int} searchLimit Limit flag per accordion summary options.
 * @property {boolean} showSearchHeader Show search header.
 */

// Define the initial state using that type
const initialState = {
  filters: [],
  hasFilters: false,
  query: "",
  isOpen: [],
  searchLimiter,
  searchLimit: 6,
  showSearchHeader: false,
};

export const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    /**
     * @memberof slice:searchFilterSlice
     * @method setSearchFilter
     * @description Adding and manipulating search filter boolean options.
     * @property {Object} payload {filters:Array<Object>, query:string}
     */
    setSearchFilter: (state, action) => {
      state.filters = action.payload.filters;
      state.query = action.payload.query;
      if (action.payload && action.payload?.filters?.length > 0) {
        state.hasFilters = true;
        state.isOpen = [];
        action.payload.filters.map((val) => {
          state.isOpen = [...state.isOpen, val.name];
          val.options.map((v) => {
            if (v.isSelected) {
              const index = state.isOpen.indexOf(val.name);
              if (index < 0) {
                state.isOpen.push(val.name);
              }
              return v;
            }
            return v;
          });
          return val;
        });
      } else {
        state.hasFilters = false;
      }
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method resetSearchFilter
     * @description Reset the search filter boolean options.
     */
    resetSearchFilter: (state, action) => {
      state?.filters.map((val) => {
        val.options.map((v) => {
          if (v.isSelected) {
            v.isSelected = false;
          }
          return v;
        });
        return val;
      });
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method setSearchFilterAccordion
     * @description Handle accordion open payloads
     * @property {string} payload Author|PublicationHouse|PublishDate in swedish.
     */
    setSearchFilterAccordion: (state, action) => {
      const index = state.isOpen.indexOf(action.payload);
      if (index > -1) {
        state.isOpen.splice(index, 1);
      } else {
        state.isOpen = [...state.isOpen, action.payload];
      }
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method setShowMoreOptions
     * @description Toggle show more accordion options.
     * @property {string} payload
     */
    setShowMoreOptions: (state, action) => {
      state.searchLimiter = state.searchLimiter.filter(
        (x) => x !== action.payload
      );
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method setSearchHeader
     * @description Toggle search header flag.
     * @property {boolean} payload
     */
    setSearchHeader: (state, action) => {
      state.showSearchHeader = action.payload;
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method clearSearchFilterByQuery
     * @description Clear the search filter using search text.
     * @property {string} payload
     */
    clearSearchFilterByQuery: (state, action) => {
      if (action.payload && action.payload !== state.query) {
        state.filters = [];
        state.hasFilters = false;
        state.query = "";
        state.searchLimiter = searchLimiter;
      }
    },
    /**
     * @memberof slice:searchFilterSlice
     * @method clearSearchFilter
     * @description Clear the searchFilterSlice.
     * @property {boolean} payload
     */
    clearSearchFilter: (state, action) => {
      state.filters = [];
      state.hasFilters = false;
      state.query = "";
      state.isOpen = [];
      state.searchLimiter = searchLimiter;
    },
  },
});

export const {
  setSearchFilter,
  clearSearchFilter,
  clearSearchFilterByQuery,
  setSearchFilterAccordion,
  setShowMoreOptions,
  setSearchHeader,
  resetSearchFilter,
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
