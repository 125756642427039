import { searchSuggestText } from "../ui-component/search/searchUtils";
import { SEARCH_TYPE, URI } from "./userManager";

/**
 * @category Config
 * @namespace urls
 * @description This contain predefined url parameter and url manipulators
 */

/**
 * @name DASHBOARD
 * @memberof urls
 * @description Url parameter used in dashboard screen
 */
const DASHBOARD = {
  KEY: "dashboard",
  PATH: "/dashboard",
  HOME: "/dashboard",
};

/**
 * @name NOTEBOOK
 * @memberof urls
 * @description Url parameter used in notebook screen
 */
const NOTEBOOK = {
  KEY: "notebook",
  KEY0: "notebook0",
  KEY1: "notebook1",
  KEY2: "notebook2",
  PATH: "/notebook",
  HOME: (param) => `/notebook/${param}`,
};

/**
 * @name SEARCH
 * @memberof urls
 * @description Url parameter used in search screen
 */
const SEARCH = {
  KEY: "search",
  PATH: "/search",
  HOME: ({ id, searchText }) =>
    `/reader/${id}?search=${encodeURIComponent(searchText)}`,
  VARIANTS: ({
    searchText,
    searchHouse,
    searchVariant,
    searchType,
    searchSuggest1,
    searchSuggest2,
  }) =>
    `/search?search-text=${encodeURIComponent(
      searchText
    )}&search-house=${searchHouse}&search-variant=${searchVariant}&search-type=${searchType}&search-suggest=${encodeURIComponent(
      searchSuggestText(searchSuggest1, searchSuggest2)
    )}`,
};

/**
 * @name READER
 * @memberof urls
 * @description Url parameter used in book reader screen
 */
const READER = {
  KEY: "reader",
  PATH: "/reader",
  HOME: ({ id, bookType, redirect }) =>
    `/reader/${id}?book-type=${bookType}&redirect=${redirect}`,
  SEARCH_PATH: ({
    id,
    bookType,
    searchText,
    redirect,
    searchVariant,
    searchType,
    searchHouse,
    searchSuggest1,
    searchSuggest2,
  }) =>
    `/reader/${id}?book-type=${bookType}&search-text=${encodeURIComponent(
      searchText
    )}&redirect=${redirect}&search-house=${searchHouse}&search-variant=${searchVariant}&search-type=${searchType}&search-suggest=${encodeURIComponent(
      searchSuggestText(searchSuggest1, searchSuggest2)
    )}`,
};

/**
 * @name SEARCH_PARAMETER
 * @memberof urls
 * @function
 * @param {string} key - Key is value from CONSTANTS.
 * @returns {string} - Decoded value of key.
 * @description This is used to decode the url values using above key
 */
const SEARCH_PARAMETER = (key) => {
  const parma = new URLSearchParams(window.location.search);
  return (key === CONSTANTS.SEARCH_TEXT || key === CONSTANTS.SEARCH_SUGGEST) &&
    parma?.get(key)
    ? decodeURIComponent(parma?.get(key))
    : parma?.get(key);
};

/**
 * @name CONSTANTS
 * @memberof urls
 * @description Constants parameters used in url.
 */
const CONSTANTS = {
  SEARCH_TYPE: "search-type",
  SEARCH_HOUSE: "search-house",
  SEARCH_VARIANT: "search-variant",
  SEARCH_TEXT: "search-text",
  SEARCH_SUGGEST: "search-suggest",
  MINASIDOR: "minasidor",
  ORDER_ID: "orderId",
  BOOK_TYPE: "book-type",
  CURRENT_LOCATION: "current-location",
  REDIRECT: "redirect",
};

/**
 * @name SEARCH_PAGE_URL
 * @memberof urls
 * @function
 * @param {Object} props - text, house, variant, type, textSuggestion
 * @description This method is used to manipulate the url parameter in search page.
 */
const SEARCH_PAGE_URL = (props) => {
  const { text, house, variant, type, textSuggestion } = props;
  const _house =
    house === SEARCH_TYPE.URL
      ? SEARCH_PARAMETER(CONSTANTS.SEARCH_HOUSE)
      : house;
  const _variant =
    house === SEARCH_TYPE.URL
      ? SEARCH_PARAMETER(CONSTANTS.SEARCH_VARIANT)
      : variant;
  const _type =
    house === SEARCH_TYPE.URL ? SEARCH_PARAMETER(CONSTANTS.SEARCH_TYPE) : type;
  const suggest = textSuggestion ? textSuggestion : "";

  const url = `${window.location.pathname}?search-text=${encodeURIComponent(
    text
  )}&search-house=${_house ? _house : SEARCH_TYPE.EBOKHYLLAN}&search-variant=${
    _variant ? _variant : SEARCH_TYPE.GROUP
  }&search-type=${
    _type ? _type : SEARCH_TYPE.MAYBE
  }&search-suggest=${encodeURIComponent(suggest)}`;
  window.history.replaceState(null, "E-bokhyllan", url);
};

/**
 * @name OnAppendLocationURL
 * @memberof urls
 * @function
 * @param {string} type - type can be removeSearch, search, currentLocation.
 * @param {string} value - value is from CONSTANTS
 * @description This method is to directly manipulate the url.
 */
const OnAppendLocationURL = (type, value) => {
  let url = URI.BASE_URL;
  const _searchedText = SEARCH_PARAMETER(CONSTANTS.SEARCH_TEXT);
  const _redirect = SEARCH_PARAMETER(CONSTANTS.REDIRECT);
  const _currentLocation = SEARCH_PARAMETER(CONSTANTS.CURRENT_LOCATION);
  const _bookType = SEARCH_PARAMETER(CONSTANTS.BOOK_TYPE);
  const _searchHouse = SEARCH_PARAMETER(CONSTANTS.SEARCH_HOUSE);
  const _searchType = SEARCH_PARAMETER(CONSTANTS.SEARCH_TYPE);
  const _searchVariant = SEARCH_PARAMETER(CONSTANTS.SEARCH_VARIANT);
  const _searchSuggest = SEARCH_PARAMETER(CONSTANTS.SEARCH_SUGGEST);

  url = `${window.location.pathname}?`;

  if (_bookType) {
    url = `${url}book-type=${_bookType}&`;
  }

  if (type !== "removeSearch") {
    if (type === "search") {
      if (value) {
        url = `${url}search-text=${encodeURIComponent(value)}&search-house=${
          _searchHouse ? _searchHouse : SEARCH_TYPE.EBOKHYLLAN
        }&search-variant=${
          _searchVariant ? _searchVariant : SEARCH_TYPE.GROUP
        }&search-type=${
          _searchType ? _searchType : SEARCH_TYPE.MAYBE
        }&search-suggest=${encodeURIComponent(
          searchSuggestText(_searchSuggest, null)
        )}&`;
      }
    } else {
      if (_searchedText) {
        url = `${url}search-text=${encodeURIComponent(
          _searchedText
        )}&search-house=${
          _searchHouse ? _searchHouse : SEARCH_TYPE.EBOKHYLLAN
        }&search-variant=${
          _searchVariant ? _searchVariant : SEARCH_TYPE.GROUP
        }&search-type=${
          _searchType ? _searchType : SEARCH_TYPE.MAYBE
        }&search-suggest=${encodeURIComponent(
          searchSuggestText(_searchSuggest, null)
        )}&`;
      }
    }
  }

  if (_redirect) {
    url = `${url}redirect=${_redirect}&`;
  }

  if (type === "currentLocation") {
    if (value) {
      url = `${url}current-location=${value}`;
    }
  } else {
    if (_currentLocation) {
      url = `${url}current-location=${_currentLocation}`;
    }
  }
  window.history.replaceState(null, "E-bokhyllan", url);
};

const URLS = {
  DASHBOARD,
  NOTEBOOK,
  READER,
  SEARCH,
  SEARCH_PARAMETER,
  SEARCH_PAGE_URL,
  CONSTANTS,
  OnAppendLocationURL,
};
export default URLS;
