import AppString from "./appString";

/**
 * @category Config
 * @namespace UserManager
 * @description This contain predefined url parameter and url manipulators
 */

/**
 * @name userManager
 * @memberof UserManager
 * @description This contains the user login authentication parameter.
 */
const userManager = {
  authority: ` ${process.env.REACT_APP_AUTH_AUTHORITY}`,
  clientId: "digitalabokhyllanjs-v2",
  redirectUri:
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    "/callback",
  postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  responseType: "code",
  scope: "openid profile SvenksbAPI userid sb-publicapi",
  silentRedirectUri:
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    "/silent",
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 300,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

/**
 * @name URI
 * @memberof UserManager
 * @description Main internal urls for the application
 */
const URI = {
  // Dev details
  BASE_URL: window.location.hostname.includes("deploy")
    ? process.env.REACT_APP_BASE_URL_SLOT
    : process.env.REACT_APP_BASE_URL,
  BASE_EPUB_FILE: process.env.REACT_APP_EPUB_FILE,
  BASE_URL_IMAGE: process.env.REACT_APP_BASE_URL_IMAGE,
  SESSION_KEY: process.env.REACT_APP_SESSION_KEY,
  VERSION: process.env.REACT_APP_ENV === "production" ? 2 : 12,
  minaSidorProductUrl: process.env.REACT_APP_MINA_SIDOR_PRODUCT_URL,

  //local
  // BASE_URL: "https://localhost:50417/",

  EXTRACTED_STORAGE_URL: process.env.REACT_APP_EXTRACTED_STORAGE_URL,
  EXTRACTED_STORAGE_IMAGE_URL:
    process.env.REACT_APP_EXTRACTED_STORAGE_IMAGE_URL,
  HOST_BLOB_NAME: process.env.REACT_APP_HOST_BLOB_NAME,
};

/**
 * @name ExternalLinks
 * @memberof UserManager
 * @description External urls for the application
 */
const ExternalLinks = {
  lagarOchRegler: process.env.REACT_APP_LAGAR_OCH_REGLER,
  kundService: process.env.REACT_APP_CUSTOMER_SERVICE,
  minaSidor: process.env.REACT_APP_MY_PAGES,
  playStore: process.env.REACT_APP_PLAY_STORE,
  appStore: process.env.REACT_APP_APP_STORE,
  fragorOchSvar: process.env.REACT_APP_QUESTIONS_AND_ANSWERS,
  eBooker: process.env.REACT_APP_EBOOKS,
  androidStaticDeepLink: process.env.REACT_APP_ANDROID_STATIC_DEEP_LINK,
  iosStaticDeepLink: process.env.REACT_APP_IOS_STATIC_DEEP_LINK,
  androidStoreDeepLink: process.env.REACT_APP_ANDROID_STORE_DEEP_LINK,
  iosStoreDeepLink: process.env.REACT_APP_IOS_STORE_DEEP_LINK,
  bookStore: process.env.REACT_APP_BOOKSTORE,
  chrome: "https://www.google.com/intl/sv/chrome/",
  edge: "https://www.microsoft.com/en-us/edge",
  safari: "https://support.apple.com/downloads/safari",
};

/**
 * @name HomeUrl
 * @memberof UserManager
 * @description This is to get hard reset home url
 */
const HomeUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "") +
  "/dashboard";

/**
 * @name EXTERNAL_IMAGE
 * @memberof UserManager
 * @description This is to get external image urls
 */
export const EXTERNAL_IMAGE = [
  process.env.REACT_APP_EXTERNAL_IMAGE_DEV,
  process.env.REACT_APP_EXTERNAL_IMAGE_PROD,
];

/**
 * @name SearchParameters
 * @memberof UserManager
 * @description This has search parameter used in search page.
 */
export const SearchParameters = {
  autocompleteMinChar: 4,
  searchMinChar: 3,
  searchMaxChar: 150,
  previous: "previous",
  next: "next",
  none: "none",
  all: "all",
};

/**
 * @name NotebookType
 * @memberof UserManager
 * @description This has notes type
 */
const NotebookType = {
  highlights: "highlights",
  bookmarks: "bookmarks",
  notes: "notes",
};

/**
 * @name SEARCH_TYPE
 * @memberof UserManager
 * @description This is used in search page for group and listed search.
 */
const SEARCH_TYPE = {
  GROUP: "group",
  LIST: "list",
  EXTERNAL: "external",
  EBOKHYLLAN: "ebokhyllan",
  EXACT: "exact",
  MAYBE: "maybe",
  RELEVANT: "relevant",
  URL: "url",
};

/**
 * @name BookType
 * @memberof UserManager
 * @description This is used to distinguish between E-bokhyllan and external books.
 */
export const BookType = {
  Ebokhyllan: "ebokhyllan",
  MinaSidor: "minasidor",
};

/**
 * @name UserSession
 * @memberof UserManager
 * @description This is used whenever session id is need to be bypassed
 */
export const UserSession = {
  sessionOverride: process.env.REACT_APP_SESSION_OVERRIDE,
};

/**
 * @name AssetType
 * @memberof UserManager
 * @description This is used to check/set asset is image/video/none in book reader screen.
 */
const AssetType = {
  NONE: "none",
  IMAGE: "img",
  VIDEO: "video",
};

/**
 * @name BookPopoverParams
 * @memberof UserManager
 * @description This is used asset is image/video in book reader screen.
 */
export const BookPopoverParams = [AssetType.IMAGE, AssetType.VIDEO];

/**
 * @name FeedbackEmails
 * @memberof UserManager
 * @description This contains feedback to mail ids
 */
const FeedbackEmails = [
  "noreply.ebokhyllan@gmail.com",
  "islam.aly@byggtjanst.se",
];

/**
 * @name BookShelfId
 * @memberof UserManager
 * @description Static bookshelf ids
 */
const BookShelfId = {
  DEPRECATED_ID: "00000000-0000-0000-0000-000000000000",
  FAVORITE_ID: "11111111-1111-1111-1111-111111111111",
  PERSONAL_ID: "22222222-2222-2222-2222-222222222222",
};

/**
 * @name MOBILE_PROMOTION_EXPIRY
 * @memberof UserManager
 * @description Mobile promotion expiry
 */
const MOBILE_PROMOTION_EXPIRY = 30;

/**
 * @name BROWSER_SUPPORT_EXPIRY
 * @memberof UserManager
 * @description Browser support expiry
 */
const BROWSER_SUPPORT_EXPIRY = 30;

/**
 * @name NotebookEmptyData
 * @memberof UserManager
 * @description Error message when no notes
 */
const NotebookEmptyData = {
  highlights: {
    title: AppString.notebook.emptyData.highlight.title,
    description: AppString.notebook.emptyData.highlight.description,
  },
  notes: {
    title: AppString.notebook.emptyData.note.title,
    description: AppString.notebook.emptyData.note.description,
  },
  bookmarks: {
    title: AppString.notebook.emptyData.bookmark.title,
    description: AppString.notebook.emptyData.bookmark.description,
  },
};

/**
 * @name AppTracker
 * @memberof UserManager
 * @description GTM ids and domain blockers
 */
const AppTracker = {
  gtm: process.env.REACT_APP_GTM,
  allowedEmailDomains: process.env.REACT_APP_BLOCKED_GA_MAIL_DOMAIN.split(" "),
};

export {
  userManager,
  URI,
  ExternalLinks,
  FeedbackEmails,
  HomeUrl,
  BookShelfId,
  MOBILE_PROMOTION_EXPIRY,
  BROWSER_SUPPORT_EXPIRY,
  AssetType,
  SEARCH_TYPE,
  NotebookType,
  NotebookEmptyData,
  AppTracker,
};
