import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:bookToolsPopupSlice
 * @description book data redux.
 */

/**
 * @memberof slice:bookToolsPopupSlice
 * @name initialState
 * @description Initial slice state
 * @property {false} initialPopup Toggle book reader popup.
 * @property {boolean} notepadPopup Toggle notes editor popup.
 * @property {int} screenX Mouse pointer x-axis value.
 * @property {int} screenY Mouse pointer y-axis value.
 * @property {string} cfi Book selected cfi.
 * @property {string} text Cfi selected text.
 * @property {Object} contents Cfi selected contents.
 * @property {string} noteText User notes text.
 * @property {int} multipleHighlight Is multiple highlight.
 * @property {int} iframeWidth Iframe width of th book reader.
 * @property {int} iframeHeight Iframe height of th book reader.
 */

const initialState = {
  initialPopup: false,
  notepadPopup: false,
  screenX: window.innerWidth / 2,
  screenY: window.innerHeight / 2,
  cfi: "",
  text: "",
  contents: null,
  noteText: "",
  multipleHighlight: false,
  iframeWidth: 0,
  iframeHeight: 0,
};

const removeSelected = (state) => {
  try {
    state?.contents?.window?.getSelection()?.removeAllRanges();
  } catch (e) {}
};

export const bookToolsPopupSlice = createSlice({
  name: "bookToolsPopup",
  initialState,
  reducers: {
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method setInitialPopup
     * @description Set book reader popup parameters.
     * @param {Object} payload {initialPopup:boolean, screenX:int,screenY:int, iframeWidth:int, iframeHeight:int, cfi:string, contents:Object, text:string}
     */
    setInitialPopup: (state, action) => {
      state.initialPopup = true;
      state.screenX = action.payload.screenX;
      state.screenY = action.payload.screenY;
      state.iframeWidth = action.payload.iframeWidth;
      state.iframeHeight = action.payload.iframeHeight;
      state.cfi = action.payload.cfi;
      state.contents = action.payload.contents;
      state.text = action.payload?.contents?.window?.getSelection()?.toString()
        ? action.payload?.contents?.window?.getSelection()?.toString()
        : "";
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method setMultipleHighlight
     * @description Set the book multiple highlight option.
     * @param {boolean} payload Multiple highlight flag
     */
    setMultipleHighlight: (state, action) => {
      state.multipleHighlight = action.payload;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method setNotepadPopup
     * @description Set user notes editor popup.
     */
    setNotepadPopup: (state, action) => {
      state.initialPopup = false;
      state.notepadPopup = true;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method toggleInitialPopup
     * @description Toggle the book reader popup
     * @param {boolean} payload Initial popup
     */
    toggleInitialPopup: (state, action) => {
      state.initialPopup = action.payload;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method toggleNotepadPopup
     * @description Toggle user notes popup
     * @param {Object} payload notepad popup
     */
    toggleNotepadPopup: (state, action) => {
      state.notepadPopup = action.payload;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method onNoteText
     * @description User notes text from editor popup.
     * @param {string} payload Note text
     */
    onNoteText: (state, action) => {
      state.noteText = action.payload;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method clearToolPopupView
     * @description Clear all the reader popups.
     */
    clearToolPopupView: (state, action) => {
      state.initialPopup = false;
      state.notepadPopup = false;
      state.screenX = 0;
      state.screenY = 0;
      state.text = "";
      state.contents = null;
      state.noteText = "";
      state.multipleHighlight = false;
    },
    /**
     * @memberof slice:bookToolsPopupSlice
     * @method clearToolPopup
     * @description Clear the bookToolsPopupSlice
     */
    clearToolPopup: (state, action) => {
      removeSelected(state);
      state.initialPopup = false;
      state.notepadPopup = false;
      state.screenX = window.innerWidth / 2;
      state.screenY = window.innerHeight / 2;
      state.text = "";
      state.contents = null;
      state.noteText = "";
      state.multipleHighlight = false;
      state.iframeWidth = 0;
      state.iframeHeight = 0;
    },
  },
});

export const {
  setInitialPopup,
  setNotepadPopup,
  clearToolPopup,
  toggleInitialPopup,
  toggleNotepadPopup,
  onNoteText,
  clearToolPopupView,
  setMultipleHighlight,
} = bookToolsPopupSlice.actions;

export default bookToolsPopupSlice.reducer;
