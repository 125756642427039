import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppString } from "../../../config";
import { UserSession } from "../../../config/userManager";
import { initialState, API_CONSTANTS } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import {
  clearRelatedSearchDetails,
  setArticleIds,
} from "./relatedSearchDetailsSlice";

/**
 * @category Redux
 * @subcategory bookSearch
 * @namespace slice:relatedSearchSlice
 * @description relatedSearchSlice is slice for getting list of related search.
 */

/**
 * @memberof slice:relatedSearchSlice
 * @method relatedSearch
 * @async
 * @description Rest api to getting list of related search.
 * @param {string} query Related search query.
 *
 */

export const relatedSearch = createAsyncThunk(
  "books/relatedSearch",
  async (query, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearRelatedSearchDetails());
      const token = WebSession()?.access_token;
      const response = await myAxios(token, UserSession.sessionOverride).get(
        `api/search/bookstore/related-search?search=${query?.trim()}`
      );
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(
            setArticleIds(
              response.data?.data?.relatedSearches.map((x) => x.articleId)
            )
          );
          return {
            data: response.data?.data?.relatedSearches,
            totalHits: response.data?.data?.totalHits,
            searchedText: response.data?.data?.query,
            perPageCount: response.data?.data?.perPageCount,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      }
      return rejectWithValue("error on api");
    } catch (err) {
      return rejectWithValue(API_CONSTANTS.NO_DATA);
    }
  }
);

/**
 * @memberof slice:relatedSearchSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {int} totalHits Total related search results count.
 * @property {string} searchedText Text searched in api.
 * @property {int} perPageCount Related search per page count.
 */

export const relatedSearchSlice = createSlice({
  name: "relatedSearch",
  initialState: {
    ...initialState,
    totalHits: 0,
    searchedText: "",
    perPageCount: -1,
  },
  extraReducers: (builder) => {
    builder.addCase(relatedSearch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(relatedSearch.fulfilled, (state, action) => {
      const { data, totalHits, searchedText, perPageCount } = action.payload;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
      if (totalHits > perPageCount) {
        state.data = [
          ...data,
          {
            id: "0zza01",
            type: AppString.relatedSearch.showMore,
            link: `sok?searchTerm=${searchedText}&contentType=book`,
          },
        ];
      } else {
        state.data = data;
      }
      state.searchedText = searchedText;
      state.totalHits = totalHits;
      state.perPageCount = perPageCount;
    });

    builder.addCase(relatedSearch.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
      state.data = null;
      state.totalHits = 0;
      state.perPageCount = -1;
      state.searchedText = "";
    });
  },
  reducers: {
    /**
     * @memberof slice:relatedSearchSlice
     * @method setRelatedSearchLoader
     * @description Toggle related search loader.
     * @property {boolean} payload
     */
    setRelatedSearchLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    /**
     * @memberof slice:relatedSearchSlice
     * @method clearRelatedSearch
     * @description Clear the relatedSearchSlice.
     */
    clearRelatedSearch: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.totalHits = 0;
      state.perPageCount = -1;
      state.searchedText = "";
    },
  },
});

export const { setRelatedSearchLoader, clearRelatedSearch } =
  relatedSearchSlice.actions;

export default relatedSearchSlice.reducer;
