/**
 * @category Config
 * @namespace AppString
 * @description This file contains all the swedish string used for the application.
 */

const AppString = {
  login: "Logga in",
  failedToLogin: "Failed to login",
  deprecated: "UPPHÄVD",
  NETWORK_ERROR: "Nätverksfel",
  common: {
    publishYear: "Utgivningsår",
  },
  searchModal: {
    removeAll: "Ta bort allt",
    autoSearch: "Auto complete", //Menade du
  },
  loader: {
    loader: "Laddar",
    preparingYourBook: "Preparing your book",
    loadingTheBook: "Laddar boken",
    bookProcessing:
      "Vänligen vänta. Det kan ta några minuter för en ny bok att ladda för första gången.",
  },
  sort: {
    titleText: "Sortering",
    default: "Standard",
    custom: "Annan",
    asc: "Stigande",
    dsc: "Fallande",
    yop: "Utgivningsår",
    popularity: "Popularitet",
    title: "Titel",
    footer: {
      submitButton: "Välj",
      cancelButton: "Avbryt",
    },
    failedToSort: "misslyckades med att sortera",
  },
  dashboard: {
    favorite: "Favoriter",
    buyBook: "Tryckt bok",
    isFavorite: "Favorit",
    notFavorite: "Ta bort favorit",
    personalBookShelf: "Min bokhylla",
    deprecatedBookShelf: "Upphävda titlar",
    purchaseLicense: "Lagar och regler",
    licenseTooltip: "Köp en licens för att få tillgång till Lagar och regler",
    advertisement:
      "Lagar och regler ger tillgång till ett 30-tal aktuella titlar inom entreprenadjuridik och byggregler. Bokhyllan uppdateras kontinuerligt med nya titlar och utgåvor. 3 690 kr/år exkl. moms. Läs mer om Lagar och regler här",
    accordionPlus: "Visa",
    accordionMinus: "Dölj",
    sortBook: "Sortera böcker",
    lasmer: "LÄS MER",
  },
  favorite: {
    addFavorite: "Boken är markerad som favorit",
    removeFavorite: "Boken tas bort från favorit",
  },
  notebook: {
    deleteSuccess: "Raderad",
    deleteFailed: "Det gick inte att radera",
    updatedSuccessfully: "Uppdateringen lyckades",
    failedToUpdate: "Det gick inte att uppdatera",
    highlightAddSuccess: "Överstrykning har lagts till",
    addSuccess: "Anteckningar har lagts till",
    addFailed: "Det gick inte att lägga till",
    highlights: "Överstrykningar",
    bookmarks: "BOKMÄRKEN",
    notes: "Anteckningar",
    bookmarkAdded: "Bokmärke tillagt",
    bookmarkFailed: "Bokmärket misslyckades",
    bookmarkRemovedSuccess: "Bokmärket har tagits bort",
    bookmarkRemovedFailed: "det gick inte att radera bokmärket",
    emptyData: {
      highlight: {
        title: "Inga överstrykningar.",
        description:
          "För att skapa en överstrykning, markera texten och klicka på en av de färgade pennorna.",
      },
      note: {
        title: "Inga anteckningar.",
        description:
          "För att skapa en anteckning, markera texten där du vill anteckna och klicka på ”anteckning”.",
      },
      bookmark: {
        title: "Inga bokmärken.",
        description:
          "Klicka på symbolen för bokmärke längst uppe till höger i boken. När symbolen färgas grön har bokmärket sparats.",
      },
    },
    alertMessage: {
      highlight:
        "Är du säker på att du vill radera anteckningen? Detta kan inte ångras.",
      bookmark:
        "Är du säker på att du vill radera bokmärket? Detta kan inte ångras.",
      note: "Är du säker på att du vill radera överstrykningen? Detta kan inte ångras.",
    },
  },
  menu: {
    dashboard: "Bokhyllor",
    notebook: "Anteckningsbok",
    minaSidor: "Mina sidor",
    kundService: "Kundservice",
    logout: "Logga ut",
    forEbokhyllan: "Förbättra E-bokhyllan",
    minaSidorLink: "https://byggtjanst.se/mina-sidor/kunduppgifter/",
    kundServiceLink: "https://byggtjanst.se/kontakta-oss",
  },
  search: {
    placeholder: "Sök här",
    fromBookshelves: "Från mina bokhyllor",
    error: "Vi hittade inga resultat, vänligen försök med ett annat sökord!",
    placeholder2: "Sök i den här boken",
    results: "Resultat",
    floatingButton: "Visa sökresultat",
    loadMore: "Ladda fler",
    pageTitle: (val) => `Sökresultat för ”${val}”`,
    suggestTitle1: (val) => `Vi hittade inga resultat för ”${val}”,`,
    suggestTitle2: (val) => `visar resultat för ”${val}” istället.`,
    internalSearch: (val) => `Från mina bokhyllor (${val})`,
    externalSearch: (val) => `Från bokhandel (${val})`,
    count: (current) => (total) => `${current}/${total} resultat`,
    listedCount: (current) => `${current} resultat`,
    groupResult: "Titel",
    listResult: "Lista",
  },
  contentTooShort: "innehållet är för kort",
  textFieldEmpty: "fältet får inte vara tomt",
  notesMenu: {
    edit: "Redigera",
    delete: "Radera",
    save: "Spara",
    cancel: "Avbryt",
    note: "ANTECKNING",
  },
  notepad: {
    placeholder: "Skriv här",
    ok: "Lägg till",
    cancel: "Avbryt",
  },
  illegalChars: "Undvik att använda '<>{}%[]_' i din text",
  feedback: {
    title: "Skicka Feedback",
    email: {
      subject: "[Ebokhyllan] You have a new feedback!",
    },
    fileSizeError: "Filstorleken är över 20mb",
    validImage:
      "Det går inte att använda den här filen. Använd formaten png, jpeg eller jpg",
    emptyData: "Berätta något",
    paragraphs: {
      line1:
        "Vi utvecklar kontinuerligt E-bokhyllan baserat på våra kunders förslag och önskemål.",
      line2:
        "Ser du något du vill förbättra eller saknar du något i E-bokhyllan?",
      line3: "Beskriv det gärna för oss i fältet nedan.",
      line4:
        "Observera att detta endast gäller feedback. För teknisk support hänvisar vi till ",
    },
    kundService: "kundservice",
    textField: {
      placeHolder: "Meddelande",
      error: "Berätta något",
    },
    screenShot: {
      title: "Bifoga en bild",
      buttonText: "Välj fil",
      error: "File size is over 20mb",
      disclaimerText: "Filstorleken får inte överstiga 20 MB",
    },
    footer: {
      submitButton: "Skicka",
      cancelButton: "Avbryt",
    },
    failed: "Din feedback kunde inte skickas",
    success: {
      title: "Tack!",
      description: "Vi har fått din feedback",
      buttonText: "Stäng",
    },
  },
  session: {
    logout: "Logga ut",
    continue: "Fortsätt",
    failed: "Misslyckades, försök igen",
    userSession: "Användarsession",
    message:
      "E-bokhyllan används på en annan enhet. Vill du öppna den här i stället?",
    refreshMessage:
      "Din session kunde inte identifieras. Klicka nedan för att starta om eller logga ut",
    refresh: "Omstart",
  },
  footer: {
    omEbokhyllan: "Frågor och Svar",
    kundService: "Kundservice",
    minaSidor: "Mina sidor",
  },
  logoutPopup: {
    title: "Du har blivit utloggad",
    content: "Vill du logga in igen?",
    yes: "Ja",
    logoutConfirmation: "Är du säker på att du vill logga ut?",
    cancel: "Avbryt",
    close: "close",
  },
  onClickError: {
    default: "Något gick fel",
    bookLoading: "Boken laddas, vänligen vänta",
    textNotFound: "Ingen text har valts",
    textSelection: "Start- eller slutval måste vara text",
    insertNotes: "Vänligen infoga några anteckningar",
    doubleSelection: "Dubbelklicka textmarkering stöds inte för Firefox",
    doubleTextSection:
      "Dubbelklicka inte för att markera text för det här avsnittet",
  },
  bookNotFound: {
    title: "Boken hittades inte",
    content: "Vänligen pröva igen senare.",
    goToScreen: "Gå till startsidan",
  },
  tooltip: {
    fullscreen: "Fullskärm",
    exitFullScreen: "Avsluta fullskärm",
    back: "Tillbaka",
    bookmark: "Bokmärke",
    toc: "Innehållsförteckning",
    highlight: "Överstrykning",
    notes: "Anteckningsbok",
    fontSize: "Ändra teckenstorlek",
    delete: "Radera",
    cancel: "Avbryt",
    bookTileMenu: "Alternativ",
    dashboardMenu: "Meny",
    notebookArrow: "",
    notebookTileMenu: "Redigera",
    searchFloatingIcon: "",
    searchIcon: "",
    searchCancelIcon: "",
    expand: "Visa",
    collapse: "Dölj",
    close: "Stäng",
    centerImage: "Bild i mitten",
    closeSearchWindow: "Stäng sökfönstret",
    highlightTileFooterClick: "Gå till överstrykning",
    bookmarkTileFooterClick: "Gå till bokmärke",
    noteBookTileFooterClick: "Gå till anteckning",
  },
  bookResume: {
    title: "Återuppta boken",
    description: "Do you want to resume from latest bookmark page?",
    ok: "Skicka",
    cancel: "Avbryt",
  },
  notSubscribed: {
    title: "You have not subscribed to this book",
    subscribe: "Subscribe",
    cancel: "cancel",
  },
  mobilePromotion: {
    browser: "webbläsare",
    chrome: "chrome",
    firefox: "firefox",
    safari: "safari",
    opera: "opera",
    edge: "edge",
    title: "Öppna E-bokhyllan i",
    open: "Öppna",
    continue: "Fortsätt",
    ebokhyllanApp: "E-bokhyllan",
    msEdge: "Microsoft Edge",
  },
  browserSupport: {
    text1: `Vänligen notera att E-bokhyllan inte tillfullo stöds av Firefox. `,
    weRecommend: "Vi rekommenderar ",
    or: "eller ",
    chrome: "Chrome",
    edge: " Microsoft edge",
    safari: " Safari",
  },
  relatedSearch: {
    noBooks: "Sorry no related books found",
    lasmer: "Läs mer",
    title: "Från bokhandel",
    showMore: "Visa fler",
    authors: "Författare",
    articleId: "Artikelnummer",
    publishYear: "Utgivningsår",
    pubisher: "Utgivare",
    isbn: "ISBN",
    pageCount: "Antal sidor",
    next: "Nästa",
    previous: "Föregående",
  },
  searchFilter: {
    title: "Sökfilter",
    publishDate: "Utgivningsår",
    publicationHouse: "Utgivare",
    author: "Författare",
  },
  bookMeta: {
    openBook: "läs boken",
    trycktBook: "Köp tryckt bok",
    author: "Av",
    publishing: "Utgivare",
    year: "Utgivningsår",
  },
};

export default AppString;
