import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SearchParameters, UserSession } from "../../../config";
import { initialState, API_CONSTANTS } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory bookSearch
 * @namespace slice:relatedSearchDetailsSlice
 * @description relatedSearchDetailsSlice is slice for getting related search details.
 */

/**
 * @memberof slice:relatedSearchDetailsSlice
 * @method relatedSearchDetails
 * @async
 * @description Rest api to getting related search details.
 * @param {int|string} id Book article id.
 *
 */

export const relatedSearchDetails = createAsyncThunk(
  "books/relatedSearchDetails",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const articleId = id ? id : getState().relatedSearchDetails.articleId;
      const cachedData = getCachedData(
        getState().relatedSearchDetails,
        articleId
      );
      if (cachedData) {
        return {
          book: cachedData,
          articleId,
          isCached: true,
        };
      }
      const token = WebSession()?.access_token;
      const response = await myAxios(token, UserSession.sessionOverride).get(
        `api/Book/bookstore/${articleId}`
      );
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return { book: response.data.data, articleId, isCached: false };
        }
      }
      return rejectWithValue(API_CONSTANTS.NO_DATA);
    } catch (err) {
      return rejectWithValue(API_CONSTANTS.NO_DATA);
    }
  }
);

const getCachedData = (state, articleId) =>
  state.cachedData.find((x) => x.articleId === articleId);

/**
 * @memberof slice:relatedSearchDetailsSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {string} articleId Article id of the book.
 * @property {boolean} isOpen Modal open/close flag.
 * @property {Array<Object>} cachedData Related books cache field.
 * @property {int} currentPosition Related books current index for carousal.
 * @property {Array<string>} articleIds List of article ids for carousal.
 * @property {string} disabledButton Disable/enable left and right carousal buttons.
 *
 */

export const relatedSearchDetailsSlice = createSlice({
  name: "relatedSearchDetails",
  initialState: {
    ...initialState,
    articleId: "",
    isOpen: false,
    cachedData: [],
    currentPosition: 0,
    articleIds: [],
    disabledButton: SearchParameters.all,
  },
  extraReducers: (builder) => {
    builder.addCase(relatedSearchDetails.pending, (state) => {
      state.isLoading = true;
      state.isOpen = true;
    });
    builder.addCase(relatedSearchDetails.fulfilled, (state, action) => {
      const { book, articleId, isCached } = action.payload;
      state.data = book;
      if (!isCached) {
        if (book?.longDescription) {
          const longDescriptionArray = book?.longDescription?.split(". ");
          if (longDescriptionArray.length > 3) {
            state.data.description1 = longDescriptionArray
              .splice(0, Math.floor(longDescriptionArray.length / 2))
              .join(". ");
            state.data.description2 = longDescriptionArray.join(". ");
          } else {
            state.data.description1 = book?.longDescription;
            state.data.description2 = "";
          }
        }
        state.cachedData = [...state.cachedData, { ...book }];
      }
      state.isLoading = false;
      state.hasData = true;
      state.hasError = false;
      state.articleId = articleId;

      const position = state.articleIds.indexOf(articleId);
      if (state.articleIds.length === 1) {
        state.disabledButton = SearchParameters.all;
      } else if (position <= 0) {
        state.disabledButton = SearchParameters.previous;
      } else if (position >= state.articleIds.length - 1) {
        state.disabledButton = SearchParameters.next;
      } else {
        state.disabledButton = SearchParameters.none;
      }
    });

    builder.addCase(relatedSearchDetails.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
      state.data = null;
      state.articleId = "";
      state.isOpen = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method setArticleIds
     * @description Set the article ids.
     * @property {Array<string>} payload
     */
    setArticleIds: (state, action) => {
      state.articleIds = action.payload;
    },
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method getPreviousRelatedArticle
     * @description Get the previous article.
     */
    getPreviousRelatedArticle: (state, action) => {
      const position = state.articleIds.indexOf(state.articleId);
      state.currentPosition = position <= 0 ? 0 : position - 1;
      if (state.currentPosition <= 0) {
        state.disabledButton = SearchParameters.previous;
      } else {
        state.disabledButton = SearchParameters.none;
      }
      state.articleId = state.articleIds[state.currentPosition];
    },
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method getNextRelatedArticle
     * @description Get the next article.
     */
    getNextRelatedArticle: (state, action) => {
      const position = state.articleIds.indexOf(state.articleId);
      state.currentPosition =
        position >= state.articleIds.length - 1
          ? state.articleIds.length - 1
          : position + 1;
      if (state.currentPosition <= 0) {
        state.disabledButton = SearchParameters.next;
      } else {
        state.disabledButton = SearchParameters.none;
      }
      state.articleId = state.articleIds[state.currentPosition];
    },
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method openRelatedSearchModal
     * @description Open the related search modal.
     * @property {string} payload
     */
    openRelatedSearchModal: (state, action) => {
      state.articleId = action.payload;
      state.isOpen = true;
    },
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method closeRelatedSearchModal
     * @description Close the related search modal.
     * @property {boolean} isOpen
     */
    closeRelatedSearchModal: (state, action) => {
      state.isOpen = false;
    },
    /**
     * @memberof slice:relatedSearchDetailsSlice
     * @method clearRelatedSearchDetails
     * @description Clear the relatedSearchDetailsSlice.
     */
    clearRelatedSearchDetails: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.articleId = "";
      state.cachedData = [];
      state.currentPosition = 0;
      state.articleIds = [];
      state.disabledButton = SearchParameters.all;
    },
  },
});

export const {
  clearRelatedSearchDetails,
  openRelatedSearchModal,
  closeRelatedSearchModal,
  setArticleIds,
  getPreviousRelatedArticle,
  getNextRelatedArticle,
} = relatedSearchDetailsSlice.actions;

export default relatedSearchDetailsSlice.reducer;
