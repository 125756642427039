import { createSlice } from "@reduxjs/toolkit";
import { URI } from "../../../config";

/**
 * @category Redux
 * @subcategory auth
 * @namespace slice:applicationVersionSlice
 * @description Managing app versioning for the application
 */

/**
 * @memberof slice:applicationVersionSlice
 * @name initialState
 * @description ApplicationVersionSlice initial state object.
 * @typedef {Object} initialState
 * @property {int} appVersion Positive integers.
 */
const initialState = {
  appVersion: URI.VERSION,
};

export const applicationVersionSlice = createSlice({
  name: "applicationVersion",
  initialState,
  reducers: {
    /**
     * @memberof slice:applicationVersionSlice
     * @method setAppVersion
     * @description Set the app version.
     * @property {int} appVersion value is positive integer.
     *
     */
    setAppVersion: (state, action) => {
      state.appVersion = action.payload;
    },
    /**
     * @memberof slice:applicationVersionSlice
     * @method clearAppVersion
     * @description Clear the app version.
     */
    clearAppVersion: (state, action) => {
      state.appVersion = {};
    },
  },
});

export const { setAppVersion, clearAppVersion } =
  applicationVersionSlice.actions;

export default applicationVersionSlice.reducer;
