import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { BookShelfId } from "../../../config";
import { API_CONSTANTS, initialState, queryConverter } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { setSuccessPersonalSortedBooks } from "../personalBookShelfSlice";
import { autoDecrementCount, clearCount } from "../reloaderSlice";

/**
 * @category Redux
 * @subcategory bookShelfSlice
 * @namespace slice:getAllBookListSlice
 * @description getAllBookListSlice is slice for getting all paid bookshelf.
 */

/**
 * @memberof slice:getAllBookListSlice
 * @method getAllBookList
 * @async
 * @description Rest api to get all paid bookshelf.
 */

export const getAllBookList = createAsyncThunk(
  "books/getAllBookList",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = WebSession()?.access_token;
    const response = await myAxios(
      token,
      getState().saveUserUniqueId.userUniqueId
    ).get("api/bookshelve?type=default");
    if (response.status === 200) {
      dispatch(clearCount("getAllBookList"));
      if (response.data.status.statusCode === 200) {
        return response.data.bookShelve;
      } else {
        return [];
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (err?.response?.status === 429) {
      dispatch(logout());
    } else if (getState().reloader["getAllBookList"]) {
      dispatch(autoDecrementCount("getAllBookList"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else if (err?.response?.status === 401) {
      dispatch(setSessionLogout(true));
      return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:getAllBookListSlice
 * @method getBookshelfAfterBookSort
 * @async
 * @description Rest api to get sorted paid bookshelf.
 * @param {string} bookShelveId Bookshelf id.
 * @param {Object} sort Sort options.
 *
 */

export const getBookshelfAfterBookSort = createAsyncThunk(
  "books/getBookshelfAfterBookSort",
  async ({ bookShelveId, sort }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get(`api/bookshelf/${bookShelveId}${queryConverter(sort)}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          if (bookShelveId === BookShelfId.PERSONAL_ID) {
            dispatch(setSuccessPersonalSortedBooks(response.data.data));
          }
          return {
            bookShelveId: bookShelveId,
            data: response.data.data,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      return rejectWithValue(err?.message);
    }
  }
);

/**
 * @memberof slice:getAllBookListSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllBookListSlice = createSlice({
  name: "getAllBookList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllBookList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllBookList.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getAllBookList.rejected, (state, action) => {
      state.hasData = false;
      state.error = action;
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getBookshelfAfterBookSort.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getBookshelfAfterBookSort.fulfilled, (state, action) => {
      const { bookShelveId, data } = action.payload;
      state.isLoading = false;
      if (bookShelveId !== BookShelfId.PERSONAL_ID) {
        const objIndex = state.data.findIndex((obj) => obj.id === bookShelveId);
        if (objIndex !== -1) {
          const updatedObj = data;
          state.data = [
            ...state.data.slice(0, objIndex),
            updatedObj,
            ...state.data.slice(objIndex + 1),
          ];
        }
      }
    });

    builder.addCase(getBookshelfAfterBookSort.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getAllBookListSlice
     * @method updateBookForFavorite
     * @description update the favorite flag inside bookshelf.
     * @param {Object} payload {bookId:string, isFavourite:boolean}
     */
    updateBookForFavorite: (state, action) => {
      const { bookId, isFavourite } = action.payload;
      state.data?.map((val) => {
        val?.books.map((v) => {
          if (v.id === bookId) {
            v.isFavourite = isFavourite;
          }
          return v;
        });
        return val;
      });
    },
    /**
     * @memberof slice:getAllBookListSlice
     * @method clearBookListData
     * @description Clear the getAllBookListSlice.
     */
    clearBookListData: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
    /**
     * @memberof slice:getAllBookListSlice
     * @method setSuccessBookList
     * @description Success bookshelf result.
     * @param {Object} payload
     */
    setSuccessBookList: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getAllBookListSlice
     * @method setBookShelfBookDetails
     * @description this method is to add book details grid to existing paid bookshelf list.
     * @param {Object} payload {bookId:string, bookshelfId:string, numberOfGridPerRow:int}
     */
    setBookShelfBookDetails: (state, action) => {
      const { bookId, bookshelfId, numberOfGridPerRow } = action.payload;
      const shelfIndex = state.data.findIndex(
        (shelf) => shelf.id === bookshelfId
      );
      if (shelfIndex >= 0) {
        const shelf = state.data[shelfIndex];
        const bookIndex = shelf.books.findIndex((book) => book.id === bookId);
        if (bookIndex >= 0) {
          const _book = shelf.books[bookIndex];
          const gridIndex =
            (Math.floor(bookIndex / numberOfGridPerRow) + 1) *
            numberOfGridPerRow;
          console.log(current(shelf.books));

          shelf.books = shelf.books.filter((book) => !book.isClicked);

          shelf.books.splice(gridIndex, 0, {
            ..._book,
            isClicked: true,
          });
          console.log(shelf.books);
          state.data[shelfIndex] = {
            ...shelf,
            clickId: `${bookshelfId}-${bookId}`,
          };
        }
      }

    },
    /**
     * @memberof slice:getAllBookListSlice
     * @method resetBookShelfBookDetails
     * @description Close or reset the book details grid in paid bookshelf.
     */
    resetBookShelfBookDetails: (state, action) => {
      try {
        state.data = state.data?.map((shelf, index) => {
          shelf.books = shelf?.books?.filter((x) => !x?.isClicked);
          return (shelf = {
            ...shelf,
            clickId: "",
          });
        });
      } catch (ex) {}
    },
  },
});

export const {
  clearBookListData,
  setSuccessBookList,
  updateBookForFavorite,
  setBookShelfBookDetails,
  resetBookShelfBookDetails,
} = getAllBookListSlice.actions;

export default getAllBookListSlice.reducer;
