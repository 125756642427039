import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { API_CONSTANTS, initialState } from "../../utils";
import { setSuggestedText } from "../searchHistory/searchHistory";
import { relatedSearch, setRelatedSearchLoader } from "./relatedSearchSlice";
import { setSearchFilter, setSearchHeader } from "./searchFilterSlice";
import { searchBody } from "./searchUtils";

/**
 * @category Redux
 * @subcategory bookSearch
 * @namespace slice:groupedSearchSlice
 * @description groupedSearchSlice is slice for getting  grouped search result.
 */

/**
 * @memberof slice:groupedSearchSlice
 * @method groupedSearchPageIndex
 * @async
 * @description Rest api to getting grouped search results.
 * @param {string} userSearchText User searched text.
 * @param {string} searchText Searched text.
 * @param {string} pageIndex Current grouped page index.
 * @param {boolean} isInitial Get api reload flag.
 * @param {Object} userFilter Applied user filters.
 *
 */

var _searchText = "";

export const groupedSearchPageIndex = createAsyncThunk(
  "books/groupedSearchPageIndex",
  async (
    { userSearchText, searchText, pageIndex, isInitial, userFilter },
    { rejectWithValue, getState, dispatch }
  ) => {
    _searchText = searchText;
    try {
      if (isInitial) {
        dispatch(clearGroupedSearchPageCache());
      }
      if (!pageIndex) {
        if (
          !getState().relatedSearch.searchedText ||
          !(
            getState().relatedSearch.searchedText ===
              getState().searchHistory.suggestedText ||
            getState().relatedSearch.searchedText ===
              getState().searchHistory.userSearchedText
          )
        ) {
          dispatch(setRelatedSearchLoader(true));
        }
      }
      const cachedBook = getCachedGroupedBooks(
        getState().groupedSearch,
        searchText,
        pageIndex,
        isInitial,
        userSearchText
      );
      if (cachedBook) {
        return {
          ...cachedBook,
          searchedText: searchText,
          pageIndex: pageIndex ? parseInt(pageIndex) : 0,
          isFromCache: true,
        };
      }
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(
        `api/search/grouped-search`,
        searchBody(
          searchText,
          pageIndex,
          "",
          getState().searchFilter.filters,
          userFilter
        )
      );
      if (response.status === 200) {
        dispatch(
          setSearchFilter({
            filters: response.data?.data?.searchFilters,
            query: userSearchText,
          })
        );
        if (response.data.status.statusCode === 200) {
          const _data = response.data.data;
          if (!pageIndex) {
            dispatch(setSearchHeader(true));
            _searchText = _data.suggestQuery
              ? _data.suggestQuery
              : _data.userQuery;
            dispatch(
              setSuggestedText({
                suggestedText: _data.suggestQuery,
                userSearchedText: _data.userQuery,
              })
            );
          }
          return {
            ..._data,
            searchedText: searchText,
            pageIndex: _data.pageIndex ? parseInt(_data.pageIndex) : 0,
            isFromCache: false,
            userQuery: _data.userQuery,
            suggestQuery: _data.suggestQuery,
            isInitial,
          };
        } else {
          dispatch(
            setSuggestedText({
              suggestedText: null,
              userSearchedText: _searchText,
            })
          );
          dispatch(setSearchHeader(false));
          dispatch(setRelatedSearchLoader(false));
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        dispatch(setSearchHeader(false));
        dispatch(
          setSuggestedText({
            suggestedText: null,
            userSearchedText: _searchText,
          })
        );
        dispatch(setRelatedSearchLoader(false));
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    } finally {
      if (!pageIndex) {
        if (getState().relatedSearch.searchedText !== _searchText) {
          dispatch(relatedSearch(_searchText));
        }
      }
    }
  }
);

/**
 * @memberof slice:groupedSearchSlice
 * @method getCachedGroupedBooks
 * @description Method to show cached result or not
 * @param {string} userSearchText User searched text.
 * @param {string} searchedText Searched text.
 * @param {string} pageIndex Current grouped page index.
 * @param {boolean} isInitial Get api reload flag.
 * @param {Object} state Redux state.
 *
 * @returns {Array<Object>} cachedData
 */

const getCachedGroupedBooks = (
  state,
  searchedText,
  pageIndex,
  isInitial,
  userSearchText
) => {
  try {
    return state.cachedData.find(
      (val) =>
        val.pageIndex === pageIndex &&
        (val.searchedText === searchedText ||
          val.suggestQuery === searchedText) &&
        (!isInitial || userSearchText === val.searchedText)
    );
  } catch {
    return null;
  }
};

/**
 * @memberof slice:groupedSearchSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {string} searchedText Text searched in api.
 * @property {string} pageIndex Current grouped search page index.
 * @property {int} nextIndex Next grouped search page index.
 * @property {boolean} showLoadMore Toggle show more button flag.
 * @property {int} currentCount Grouped search current result count.
 * @property {int} totalCount Total grouped search results count.
 * @property {Array<Object>} cachedData Grouped search cached data.
 * @property {int} perPageCount Grouped search per page count.
 * @property {string} suggestQuery Text suggestion.
 *
 */

export const groupedSearchSlice = createSlice({
  name: "groupedSearch",
  initialState: {
    ...initialState,
    searchedText: "",
    pageIndex: "",
    nextIndex: 0,
    showLoadMore: false,
    currentCount: 0,
    totalCount: 0,
    cachedData: [],
    perPageCount: 10,
    suggestQuery: "",
  },
  extraReducers: (builder) => {
    builder.addCase(groupedSearchPageIndex.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(groupedSearchPageIndex.fulfilled, (state, action) => {
      const {
        books,
        searchedText,
        pageIndex,
        currentCount,
        totalCount,
        isFromCache,
        perPageCount,
        suggestQuery,
        isInitial,
      } = action.payload;
      if (!isFromCache && books?.length > 0) {
        let _cachedData = state.cachedData;
        state.cachedData = [
          ..._cachedData,
          {
            pageIndex,
            books,
            currentCount,
            totalCount,
            searchedText,
            perPageCount,
            suggestQuery,
          },
        ];
      }
      if (isInitial) {
        state.suggestQuery = suggestQuery;
        state.searchedText = searchedText;
      }
      state.data = books;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;
      state.pageIndex = pageIndex;
      state.currentCount = currentCount;
      state.totalCount = totalCount;
      state.perPageCount = perPageCount;

      if (totalCount > perPageCount) {
        state.showLoadMore = true;
      } else {
        state.showLoadMore = false;
      }
    });

    builder.addCase(groupedSearchPageIndex.rejected, (state, action) => {
      state.showLoadMore = false;
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
      state.data = null;
      state.searchedText = "";
      state.suggestQuery = "";
      state.currentCount = 0;
      state.totalCount = 0;
      state.pageIndex = "";
      state.suggestQuery = "";
    });
  },
  reducers: {
    /**
     * @memberof slice:groupedSearchSlice
     * @method groupedSearchClearData
     * @description Clear the groupedSearchClearData.
     */
    groupedSearchClearData: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.searchedText = "";
      state.suggestQuery = "";
    },
    /**
     * @memberof slice:groupedSearchSlice
     * @method clearGroupedSearchPageIndex
     * @description Clear the pageIndex and show load more.
     */
    clearGroupedSearchPageIndex: (state) => {
      state.pageIndex = "";
      state.showLoadMore = false;
    },
    /**
     * @memberof slice:groupedSearchSlice
     * @method clearGroupedSearchPageCache
     * @description Clear the grouped search cached data.
     */
    clearGroupedSearchPageCache: (state) => {
      state.cachedData = [];
      state.suggestQuery = "";
    },
    /**
     * @memberof slice:groupedSearchSlice
     * @method clearGroupedSearchText
     * @description Clear the grouped search searched text.
     */
    clearGroupedSearchText: (state) => {
      state.searchedText = "";
    },
  },
});

export const {
  groupedSearchClearData,
  clearGroupedSearchPageIndex,
  clearGroupedSearchPageCache,
  clearGroupedSearchText,
} = groupedSearchSlice.actions;

export default groupedSearchSlice.reducer;
