import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import axios from "axios";
import { URI } from "../../../config";

/**
 * @category Redux
 * @subcategory bookmark
 * @namespace slice:getBookmarkByIdSlice
 * @description getBookmarkByIdSlice is slice for getting bookmarks by book id.
 */

/**
 * @memberof slice:getBookmarkByIdSlice
 * @method getBookmarkById
 * @async
 * @description Rest api to get bookmarks by book id.
 * @param {string} bookId Book id for the bookmark.
 * @param {boolean} isInitial If request is made for first time.
 */

export const getBookmarkById = createAsyncThunk(
  "book/getBookmarkById",
  async (
    { bookId, isInitial },
    { rejectWithValue, getState, dispatch, signal }
  ) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`api/bookmark/${bookId}`, {
        baseURL: URI.BASE_URL,
        headers: {
          Authorization: "Bearer " + token,
          "user-unique-id": getState().saveUserUniqueId.userUniqueId,
        },
        cancelToken: source.token,
      });
      signal.removeEventListener("abort", null, true);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return {
            bookId,
            data: response.data?.data,
            isInitial,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getBookmarkByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @property {Array<Object>} cachedData Store the bookmark data by book id
 * @description Initial slice state
 */

export const getBookmarkByIdSlice = createSlice({
  name: "getBookmarkById",
  initialState: {
    ...initialState,
    cachedData: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getBookmarkById.pending, (state, { meta }) => {
      const { bookId, isInitial } = meta.arg;
      const cachedData = state.cachedData?.find((val) => val.bookId === bookId);
      if (isInitial && cachedData) {
        state.data = cachedData?.data;
        state.hasData = true;
        state.hasError = false;
        state.error = null;
      } else {
        state.isLoading = true;
      }
    });

    builder.addCase(getBookmarkById.fulfilled, (state, action) => {
      const { bookId, data } = action.payload;
      state.hasData = true;
      state.data = data;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;
      const index = state.cachedData?.findIndex((val) => val.bookId === bookId);
      if (index === -1) {
        state.cachedData.push({
          bookId,
          data,
        });
      } else {
        state.cachedData[index] = { bookId, data };
      }
    });

    builder.addCase(getBookmarkById.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method setBookmarkCachedData
     * @description Caching the bookmark data by book id.
     * @property {string} bookId
     */
    setBookmarkCachedData: (state, action) => {
      const { bookId } = action.payload;
      const index = state.cachedData?.findIndex((val) => val.bookId === bookId);
      if (index > -1) {
        state.cachedData?.splice(index, 1);
      }
    },
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method clearBookmarkCachedData
     * @description Clear all cached bookmark data.
     */
    clearBookmarkCachedData: (state, action) => {
      state.cachedData = [];
    },
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method clearGetBookmarkById
     * @description Clear the getBookmarkById.
     */
    clearGetBookmarkById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method setSuccessGetBookmarkByBookId
     * @description Set the getBookmarkByIdSlice with success data.
     * @property {Object} payload
     */
    setSuccessGetBookmarkByBookId: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method setErrorGetBookmarkByBookId
     * @description Set the getBookmarkByIdSlice with error data.
     * @property {string} payload
     */
    setErrorGetBookmarkByBookId: (state, action) => {
      state.hasData = false;
      state.hasError = true;
      state.error = action.payload;
      state.data = null;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getBookmarkByIdSlice
     * @method clearAllGetBookmarkById
     * @description Clear the getBookmarkById.
     */
    clearAllGetBookmarkById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.cachedData = [];
    },
  },
});

export const {
  clearGetBookmarkById,
  setBookmarkCachedData,
  clearBookmarkCachedData,
  setSuccessGetBookmarkByBookId,
  setErrorGetBookmarkByBookId,
  clearAllGetBookmarkById,
} = getBookmarkByIdSlice.actions;

export default getBookmarkByIdSlice.reducer;
